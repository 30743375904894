<template>
  <div class="performance-field">
    <NormForm
      v-model="queryForm"
      :query-btn-loading="tableLoading"
      @reset="handleQueryReset"
      @query="handleQueryForm"
    >
      <el-form-item label="">
        <el-input v-model="queryForm.performanceFieldName" placeholder="客户名称或编号" clearable />
      </el-form-item>
      <el-form-item label="">
        <NormSelection
          v-model="queryForm.performanceFieldStatus"
          api-key="PerformanceFieldStatus"
          placeholder="状态"
        />
      </el-form-item>
      <el-form-item label="">
        <NormSelection
          v-model="queryForm.performanceFieldType"
          :select-options="typeOptions"
          placeholder="类型"
        />
      </el-form-item>
    </NormForm>
    <vxe-toolbar>
      <template v-slot:buttons>
        <el-button type="primary" @click="handleAdd">新建</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      v-loading="tableLoading"
      :show-overflow="false"
      max-height="550px"
      align="center"
      border
      :data="tableData"
    >
      <vxe-table-column field="performanceFieldId" title="编号" min-width="100" />
      <vxe-table-column field="performanceFieldName" title="名称" min-width="100" />
      <vxe-table-column field="status" title="状态" min-width="100">
        <template v-slot="{row}">
          <el-tag v-if="row.performanceFieldStatus" size="mini">{{ row.status }}</el-tag>
          <el-tag v-else size="mini" type="danger">{{ row.status }}</el-tag>
        </template>
      </vxe-table-column>

      <vxe-table-column field="type" title="类型" min-width="100" />
      <vxe-table-column field="createByName" title="添加人员" min-width="100" />
      <vxe-table-column field="createTime" title="新增时间 " min-width="200" />
      <vxe-table-column title="操作" min-width="100">
        <template v-slot="{row}">
          <el-button v-if="row.performanceFieldStatus" type="text" style="color: #ff4949" @click="handleDisable(row)">禁用</el-button>
          <el-button v-else type="text" @click="_updatePerformanceField({...row, performanceFieldStatus: 1})">启用</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <Paging style="float: right" class="my-2" :pager="pager" end @update="pagerUpdate" />
    <el-dialog
      class="add-dialog"
      title="新增字段"
      :visible.sync="visible"
      width="500px"
    >
      <el-form ref="addForm" style="max-hight:80vh" :model="addForm" label-width="80px">
        <el-form-item
          v-for="(item, index) in addForm.insertField"
          :key="index"
          class="batch-items"
          label="字段名称"
          :prop="'insertField.' + index + '.performanceFieldName'"
          :rules="[
            { required: true, message: '字段名称不能为空', trigger: ['blur', 'change'] },
            {required: true, trigger: 'blur', validator: validatePass }
          ]"
        >
          <el-input v-model="item.performanceFieldName" />
          <div class="batchActive ml-3">
            <i v-show="index !== 0" class="el-icon-remove" @click="handleRemoveField(index)" />
            <i class="el-icon-circle-plus" @click="handleAddField(index)" />
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" :loading="addLoading" @click="handleInsertPerformanceField">确认</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import NormForm from '@/components/NormForm'
import NormSelection from '@/components/NormSelection'
import Paging from '@/components/Paging'
import {
  getPerformanceFieldByPage,
  updatePerformanceField,
  insertPerformanceField,
  checkPerformanceFieldName
} from '@/api/performance'
export default {
  components: {
    NormForm,
    NormSelection,
    Paging
  },
  data() {
    const typeOptions = [{ value: '0', label: '系统字段' }, { value: '1', label: '自定义字段' }]
    return {
      queryForm: {
        performanceFieldName: '',
        performanceFieldStatus: '',
        performanceFieldType: ''
      },
      tableLoading: false,
      visible: false,
      tableData: [],
      addForm: {
        insertField: [{ performanceFieldName: '' }]
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      addLoading: false,
      typeOptions

    }
  },
  computed: {
    getPerformanceFieldByPageParams() {
      return Object.assign({}, this.queryForm, this.pager)
    },
    insertPerformanceFieldParams() {
      const { insertField } = this.addForm
      return insertField
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.$nextTick(() => {
          Object.assign(this.addForm, this.$options.data().addForm)
          this.$refs['addForm'].clearValidate()
        })
      }
    }
  },
  mounted() {
    this._getPerformanceFieldByPage()
  },
  methods: {
    pagerUpdate(val) {
      this.pager = val
      this._getPerformanceFieldByPage()
    },
    async validatePass(rule, value, callback) {
      const type = await checkPerformanceFieldName({ performanceFieldName: value })
      if (type) {
        callback(new Error(`【${value}】已存在!`))
      } else {
        callback()
      }
    },
    handleQueryReset() {
      Object.assign(this.queryForm, this.$options.data().queryForm)
    },
    handleQueryForm() {
      this.pager.current = 1
      this._getPerformanceFieldByPage()
    },
    handleAdd() {
      this.visible = true
    },
    async handleInsertPerformanceField() {
      this.$refs['addForm'].validate(async(valid) => {
        if (valid) {
          const fields = this.insertPerformanceFieldParams.map(item => item.performanceFieldName)
          const isSameFields = this.findRepeat(fields)
          if (isSameFields.length > 0) {
            console.log(isSameFields)
            this.$message.warning(`请勿输入重复的值【${isSameFields.join('，')}】`)
            return
          }
          try {
            this.addLoading = true
            const { msg } = await insertPerformanceField(this.insertPerformanceFieldParams)
            this.$message.success(msg)
            this.visible = false
            this._getPerformanceFieldByPage()
          } finally {
            this.addLoading = false
          }
        }
      })
    },
    findRepeat(num) {
      const map = new Map()
      const sameArr = []
      for (const i of num) {
        if (map.has(i)) {
          sameArr.push(i)
        } else {
          map.set(i, 1)
        }
      }
      return sameArr
    },
    handleDisable(row) {
      this.$confirm('该字段正在规则中被使用，禁用会导致规则数据失真，是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = { ...row, performanceFieldStatus: 0 }
        this._updatePerformanceField(data)
      }).catch(() => {})
    },
    handleRemoveField(index) {
      this.addForm.insertField.splice(index, 1)
    },
    handleAddField() {
      this.addForm.insertField.push({
        performanceFieldName: ''
      })
    },
    async _getPerformanceFieldByPage() {
      try {
        this.tableLoading = true
        const { datas: { pager, records }} = await getPerformanceFieldByPage(this.getPerformanceFieldByPageParams)
        this.tableData = records.map(item => {
          const status = item.performanceFieldStatus ? '使用中' : '未使用'
          const type = item.performanceFieldType ? '自定义字段' : '系统字段'
          return { ...item, status, type }
        })
        this.pager = pager
      } finally {
        this.tableLoading = false
      }
    },
    async _updatePerformanceField(data) {
      const { msg } = await updatePerformanceField(data)
      this.$message.success(msg)
      this._getPerformanceFieldByPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.add-dialog /deep/  {
  .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
  }
  .el-form-item__content {
    display: flex;
    .el-input--medium {
      width: 300px;
    }
    .batchActive {
      display: inline-block;
      .el-icon-remove, .el-icon-circle-plus {
        margin-top: 10px;
        font-size: 20px;
        cursor: pointer;
      }
      .el-icon-remove {
        color: #F56C6C;
      }
      .el-icon-circle-plus{
        color:#67C23A
      }
  }
  }
}
// .el-dialog__wrapper .batch-items  .el-form-item__content {
//   display: flex;
//   .el-input--medium {
//     width: 300px;
//   }
//   .batchActive {
//     display: inline-block;
//     .el-icon-remove, .el-icon-circle-plus {
//       margin-top: 10px;
//       font-size: 20px;
//       cursor: pointer;
//     }
//     .el-icon-remove {
//       color: #F56C6C;
//     }
//     .el-icon-circle-plus{
//       color:#67C23A
//     }
//   }
// }
// .el-dialog__body {
//     height: 60vh;
//     overflow-y: auto;
// }
</style>
