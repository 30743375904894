<template>
  <el-form ref="queryForm" inline :value="value" :rules="rules" :label-width="labelWidth" :model="value" class="queryForm">
    <slot />
    <span v-show="OthersIsShow">
      <slot name="others" />
    </span>
    <el-form-item class="ml-4">
      <el-button v-if="!inactive" :loading="queryBtnLoading" type="primary" @click="handleQuery">查询</el-button>
      <el-button v-if="!inactive && !noClear " @click="handleReset">清除</el-button>
      <el-button v-show="expandVisible" type="text" @click="OthersIsShow = !OthersIsShow">{{ OthersIsShow ? $t('page.hide') : $t('page.expand') }}</el-button>
    </el-form-item>
    <slot name="activeAfter" />
  </el-form>
</template>

<script>
export default {
  name: 'NormForm',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    queryBtnLoading: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: ''
    },
    expandVisible: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    },
    noClear: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      OthersIsShow: false
    }
  },
  mounted() {
    this.$emit('refNode', this.$refs.queryForm)
  },
  methods: {
    handleQuery() {
      if (Object.keys(this.rules).length !== 0) {
        this.$refs.queryForm.validate((valid, obj) => {
          if (valid) {
            this.$emit('query')
          } else {
            return false
          }
        })
      } else {
        this.$emit('query')
      }
    },
    handleReset() {
      this.$emit('reset')
    },
    getValidate() {
      return new Promise((resolve, reject) => {
        this.$refs.queryForm.validate((valid, obj) => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.queryForm /deep/ {
  .el-date-editor.el-input , .el-select .el-input__inner ,.el-input .el-input__inner {
      width: 180px ;
  }
}
</style>
